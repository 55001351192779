<template>
  <div class="faq">
    <Container>
      <Card padding="large">
        <Margins>
          <CardHeader :header="$t('FAQ.TITLE')" />
          <Accordion :options="questions" class="margins__triple" />
        </Margins>
      </Card>
    </Container>
  </div>
</template>
‌
<script>
import { Accordion, Card, CardHeader, Container, Margins } from '@/components';
import { getDynamicTranslation } from '@/utils';
import { constants } from '@/mixins';

export default {
  name: 'Faq',
  mixins: [constants],
  components: {
    Container,
    Card,
    CardHeader,
    Margins,
    Accordion,
  },
  computed: {
    questions() {
      return getDynamicTranslation(this.$i18n, 'FAQ.FAQ_LIST');
    },
  },
};
</script>
